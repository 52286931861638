<template>
  <div>
    <div>
      <!----- 搜索框 start------->
      <el-form :inline="true" class="search">
        <el-form-item label="项目">
          <el-select clearable style="width: 120px" filterable v-model="searchData.project_id" placeholder="项目">
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable style="width: 120px" v-model="searchData.status" placeholder="状态">
            <el-option label="启用" :value="1"></el-option>
            <el-option label="结束" :value="10"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="searchData.name" placeholder="名称"/>
        </el-form-item>
        <el-form-item>
          <el-button @click="getContracts()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="success" @click.stop="clickContactCreate()">添加合同</el-button>
          <el-button type="primary" @click.stop="clickCreate()">添加项目</el-button>
        </el-form-item>
      </el-form>
      <!----- 搜索框 end------->
      <div style="clear: both"></div>
    </div>

    <el-table :data="contracts" border>
      <el-table-column label="名称">
        <template v-slot="scope">
          {{ scope.row.project.name + scope.row.remark }}
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="date"></el-table-column>
      <el-table-column label="合同金额" prop="amount"></el-table-column>
      <el-table-column label="已收" prop="pay_sum">
        <template v-slot="scope">
          <a href="javascript:void(0)" @click="clickContractPayShow(scope.row)">{{ scope.row.pay_sum }}</a>
        </template>
      </el-table-column>
      <el-table-column label="预算" prop="budget_sum">
        <template v-slot="scope">
          <a href="javascript:void(0)" @click="clickContractBudgetShow(scope.row)">{{ scope.row.budget_sum }}</a>
        </template>
      </el-table-column>
      <el-table-column label="支出" prop="cost_sum"></el-table-column>
      <el-table-column label="预计未付" prop="pad_amount"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="warning" size="small" @click="clickContactUpdate(scope.row)" circle>改</el-button>
          <el-button type="info" size="small" @click="clickContactPay(scope.row)" circle>收</el-button>
          <el-button
              :type="scope.row.status === 1 ? 'danger' : 'success'"
              size="small"
              circle
              @click="clickContactDisable(scope.row)">
          {{scope.row.status === 1 ? '停' : '启'}}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="编辑项目" fullscreen append-to-body>
      <el-form
          ref="project"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="formData.data.name" placeholder="项目名称" />
        </el-form-item>
        <el-form-item label="公司名称" prop="identity_name">
          <el-input v-model="formData.data.identity_name" placeholder="公司名称"/>
        </el-form-item>
        <el-form-item label="项目简介">
          <el-input type="textarea" rows="2" v-model="formData.data.desc" placeholder="项目简介"/>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="formData.data.sort" type="number" placeholder="序号，越大越前"/>
        </el-form-item>
      </el-form>

      <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="contractData.show" title="编辑合同" fullscreen append-to-body>
      <el-form
          ref="contract"
          :rules="contractData.rules"
          label-width="100px"
          :model="contractData.data"
      >
        <el-form-item v-show="!contractData.data.id" label="关联项目" prop="project_id">
          <el-select filterable v-model="contractData.data.project_id" placeholder="请选择项目">
            <el-option
                v-for="(item, key) in projects"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同名称" prop="remark">
          <el-input v-model="contractData.data.remark" placeholder="合同备注"/>
        </el-form-item>
        <el-form-item label="合同金额" prop="amount">
          <el-input v-model="contractData.data.amount" type="number" placeholder="合同金额"/>
        </el-form-item>
        <el-form-item label="开始时间" prop="start_at">
          <el-date-picker v-model="contractData.data.start_at" value-format="YYYY-MM-DD" type="date"
                          placeholder="开始时间"/>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_at">
          <el-date-picker v-model="contractData.data.end_at" value-format="YYYY-MM-DD" type="date"
                          placeholder="结束时间"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeContractDialog">取消</el-button>
          <el-button type="primary" @click="onContractSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="payData.show" title="项目收款" fullscreen append-to-body>
      <el-form
          ref="contract.pay"
          :rules="payData.rules"
          label-width="100px"
          :model="payData.data"
      >
        <el-form-item label="应付金额" prop="price">
          <el-input v-model="payData.data.price" placeholder="应付金额"/>
        </el-form-item>
        <el-form-item label="应付时间" prop="date">
          <el-date-picker v-model="payData.data.date" value-format="YYYY-MM-DD" type="date"
                          placeholder="应付时间"/>
        </el-form-item>
        <el-form-item label="实付金额" prop="pay_price">
          <el-input v-model="payData.data.pay_price" placeholder="实付金额"/>
        </el-form-item>
        <el-form-item label="实付时间" prop="pay_date">
          <el-date-picker v-model="payData.data.pay_date" value-format="YYYY-MM-DD" type="date"
                          placeholder="实付时间"/>
        </el-form-item>
        <el-form-item label="付款方式" prop="pay_type">
          <el-select v-model="payData.data.pay_type" >
            <el-option label="未付" :value="0"></el-option>
            <el-option label="公账" :value="1"></el-option>
            <el-option label="私账" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="payData.data.remark" placeholder="备注"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeContractPayDialog">取消</el-button>
          <el-button type="primary" @click="onContractPaySubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->


    <el-dialog v-model="viewData.show" title="预算预览" draggable>
      <el-table
          :data="viewData.tableData"
          row-key="id"
          border
          default-expand-all
          show-summary
          :summary-method="getSummaries"
          :tree-props="{children: 'children'}">
        <el-table-column
            prop="name"
            label="预算项">
        </el-table-column>
        <el-table-column label="数量" prop="number" width="150"></el-table-column>
        <el-table-column label="总价" prop="amount">
          <template v-slot="scope">
            <span v-show="scope.row.parent_id > 0" v-text="scope.row.amount"></span>
          </template>
        </el-table-column>
        <el-table-column label="已支出" prop="amount">
          <template v-slot="scope">
            <div v-show="scope.row.parent_id > 0">
              <span v-text="scope.row.cost_sum_price" style="font-weight: bold;"></span>
            </div>

          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button circle size="small" type="danger" @click="destroyBudget(scope.row, scope.index)">删</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <el-dialog v-model="contractPayData.show" title="预算预览" draggable>
      <el-table
          :data="contractPayData.tableData"
          border>
        <el-table-column prop="remark" label="说明"></el-table-column>
        <el-table-column label="应付" prop="price">
          <template v-slot="scope">
            <div>{{ scope.row.price }}</div>
            <div>{{ scope.row.date }}</div>
          </template>
        </el-table-column>
        <el-table-column label="付款" prop="price">
          <template v-slot="scope">
            <div>
              <div>{{ scope.row.pay_price }} ({{ scope.row.pay_type_desc }})</div>
              <div>{{ scope.row.pay_date }}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import {apiGetProjects, apiCreateProject, apiPutProject} from '@/api/projectApi'
import {apiStoreProjectContract, apiGetProjectContracts, apiUpdateProjectContract, apiDisableProjectContract} from '@/api/projectContractApi'
import {getCompanyId} from "@/plugins/company"
import {apiDestroyProjectBudget, apiGetProjectBudgets} from "@/api/projectBudgetApi"
import {apiGetProjectPay, apiStoreProjectPay} from "@/api/projectPayApi"

let formData = {
  id: 0,
  company_id: getCompanyId(),
  name: '',
  identity_name: '',
  desc: '',
  sort: 0,
}

let contractData = {
  id: '',
  company_id: getCompanyId(),
  project_id: '',
  remark: '',
  amount: '',
  start_at: null,
  end_at: null,
  serial_no: '',
}

let payData = {
  project_id: '',
  company_id: getCompanyId(),
  project_contract_id: '',
  date: null,
  pay_date: null,
  price: '',
  pay_price: 0,
  pay_type: 0,
  remark: '',
  serial_no: '',
}

export default {
  name: 'project.index',
  created() {
    this.getProjects()
    this.getContracts()
  },
  data() {
    return {
      // 创建表单
      formData: {
        lock: false,
        data: formData,
        rules: {
          name: [{
            required: true,
            message: '请输入项目名称',
            trigger: 'blur',
          }],
          sort: [{
            required: true,
            message: '请输入序号',
            trigger: 'blur',
          }],
        },
        show: false,
      },
      payData: {
        show: false,
        lock: false,
        data: payData,
        rules: {
          price: [{
            required: true,
            message: '请输入家呢',
            trigger: 'blur',
          }],
          date: [{
            required: true,
            message: '请选择日期',
            trigger: 'blur',
          }],
        },
      },
      viewData: {
        show: false,
        data: [],
      },
      contractPayData: {
        show: false,
        data: [],
      },
      contractData: {
        lock: false,
        show: false,
        data: contractData,
        rules: {
          remark: [{
            required: true,
            message: '请输入项目名称',
            trigger: 'blur',
          }],
          project_id: [{
            required: true,
            message: '请选择项目',
            trigger: 'blur',
          }],
          amount: [{
            required: true,
            message: '请选择项目',
            trigger: 'blur',
          }],
          start_at: [{
            required: true,
            message: '请选择开始时间',
            trigger: 'blur',
          }],
          end_at: [{
            required: true,
            message: '请选择结束时间',
            trigger: 'blur',
          }],
        },
      },
      projects: [],
      contracts: [],
      searchData: {
        company_id: getCompanyId(),
        name: '',
        status: 1,
        project_id: '',
        with_query: ['project', 'budget_sum', 'cost_sum', 'pay_sum'],
        per_page: 500
      },
    }
  },
  methods: {
    getProjects() {
      apiGetProjects({page: 1, per_page: 500, company_id: getCompanyId(),}).then((res) => {
        this.projects = res.data
      })
    },
    getContracts() {
      apiGetProjectContracts(this.searchData).then((res) => {
        this.contracts = res.data
      })
    },
    onSubmit() {
      if (this.formData.lock) {
        return;
      }
      this.formLock()
      this.$refs['project'].validate(valid => {
        if (valid) {
          // 更新逻辑
          if (this.formData.data.id > 0) {
            apiPutProject(this.formData.data.id, this.formData.data).then(() => {
              this.$message.success('修改成功')
              this.getProjects()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          } else {
            apiCreateProject(this.formData.data).then(() => {
              this.$message.success('创建成功')
              this.getProjects()
              this.closeDialog()
              this.formUnlock()
            }).catch(() => {
              this.formUnlock()
            })
          }
        }
      })
    },
    // 点击更新
    clickUpdate(row) {
      this.formData.data = {
        id: row.id,
        name: row.name,
        identity_name: row.identity_name,
        desc: row.desc,
        sort: row.sort,
      }
      this.showDialog()
    },
    destroyBudget(row, index) {
      this.$confirm('确定删除吗').then(() => {
        apiDestroyProjectBudget(row.id).then(() => {
          this.$message.success('删除成功')
          this.viewData.data.splice(index, 1)
        })
      })
    },
    // 点击创建
    clickCreate() {
      this.initDialog()
      this.showDialog()
    },
    // 添加合同
    onContractSubmit() {
      if (this.formData.lock) {
        return;
      }
      this.formContractLock()

      this.$refs['contract'].validate(valid => {
        if (valid) {
          if (this.contractData.data.id) {
            apiUpdateProjectContract(this.contractData.data.id, this.contractData.data).then(() => {
              this.getContracts()
              this.$message.success('合同修改成功')
              this.contractData.data = contractData
              this.contractData.show = false
              this.formContractUnlock()
            }).catch((e) => {
              console.log(e)
              this.formContractUnlock()
            })
          } else {
            apiStoreProjectContract(this.contractData.data).then(() => {
              this.getContracts()
              this.$message.success('合同创建成功')
              this.contractData.data = contractData
              this.contractData.show = false
              this.formContractUnlock()
            }).catch((e) => {
              console.log(e)
              this.formContractUnlock()
            })
          }
        }
      }).catch(() => {
        this.formContractUnlock()
      });
    },
    // 创建合同
    clickContactCreate() {
      this.contractData.data = contractData
      this.contractData.show = true
    },
    clickContactUpdate(item) {
      this.contractData.data = item
      this.contractData.show = true
    },
    clickContactDisable(item) {
      this.$confirm('确定要' + (item.status === 1 ? '禁用' : '启用') + '吗').then(() => {
        apiDisableProjectContract(item.id).then(() => {
          this.viewData.tableData = []
          this.getContracts()
          this.$message.success('操作成功')
        })
      })
    },
    // 项目付款
    clickContactPay(item) {
      this.payData.data.project_id = item.project_id
      this.payData.data.project_contract_id = item.id
      this.payData.show = true
    },
    onContractPaySubmit() {
      this.$refs['contract.pay'].validate(valid => {
        if (valid) {
          apiStoreProjectPay(this.payData.data).then(() => {
            this.$message.success('操作成功')
            this.getContracts()
            this.closeContractPayDialog()
          })
        }
      })
    },
    // 查看合同预算
    clickContractBudgetShow(contract) {
      apiGetProjectBudgets({
        project_contract_id: contract.id,
        parent_id: 0,
        with_query: ['child'],
        per_page: 500,
        company_id: getCompanyId(),
      }).then(rsp => {
        this.viewData.tableData = rsp.data
        this.viewData.show = true
      })
    },
    clickContractPayShow (contract) {
      apiGetProjectPay({
        project_contract_id: contract.id,
        company_id: getCompanyId(),
      }).then(rsp => {
        this.contractPayData.tableData = rsp.data
        this.contractPayData.show = true
      })
    },
    // 获取合并项
    getSummaries() {
      var total = 0
      this.viewData.tableData.forEach((data) => {
        data['children'].forEach((item) => {
          total = parseFloat(item.amount) + total
        })
      })
      return {
        0: '总计',
        1: '',
        2: parseFloat(total).toFixed(2),
      }
    },
    // 查看支出项
    clickContractCostShow(contract) {
      this.$router.push({
        name: 'project.cost',
        query: {contract_id: contract.id}
      })
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
    formContractLock() {
      this.contractData.lock = true
    },
    formContractUnlock() {
      this.contractData.lock = false
    },
    formContractPayLock() {
      this.payData.lock = true
    },
    formContractPayUnlock() {
      this.payData.lock = false
    },
    // 初始化弹出
    initDialog() {
      this.formData.data = formData
      this.closeDialog()
    },
    // 显示弹窗
    showDialog() {
      this.formData.show = true
    },
    // 关闭弹窗
    closeDialog() {
      this.formData.show = false
    },
    closeContractDialog() {
      this.contractData.data.project_id = 0
      this.contractData.show = false
    },
    closeContractPayDialog() {
      this.payData.data.project_id = 0
      this.payData.data.project_contract_id = 0
      this.payData.show = false
    },
  }
}
</script>
